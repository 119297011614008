import { IAlgoliaSearchState } from './models';

export const ALGOLIA_URL_PARAMS = {
  filterSection: 'fs',
  querySearch: 's',
};

export const HitsPerPage = 999;

export const DefaultSearchState: IAlgoliaSearchState = {
  configure: {
    analytics: false,
    distinct: true,
    hitsPerPage: HitsPerPage,
  },
  page: 1,
  optionalFilters: '',
  refinementList: {},
};
