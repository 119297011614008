import { UmbracoButton, UmbracoFilterSection, UmbracoProductQuiz } from '@shared/types';
import { IParsedAlgoliaQuizQuestion } from 'utils';

import { IParsedButtonComposition } from './models';

export const parseButtonComposition = (
  buttonData?: [UmbracoButton.IStructure]
): IParsedButtonComposition | null => {
  const source = buttonData?.[0]?.properties;
  if (!source) {
    return null;
  }

  return {
    label: source?.label || '',
    link: source?.link?.[0]?.url || '',
    ariaLabel: source?.ariaLabel || source?.label || '',
    target: source?.link?.[0]?.target || null,
  };
};

export const parseFilterSections = (
  param: [UmbracoFilterSection.IStructure]
): UmbracoFilterSection.IData[] | null =>
  param?.length
    ? param.map((item: UmbracoFilterSection.IStructure) => ({
        title: item.properties.title,
        attributeForFaceting: item.properties?.tagsTaxonomy?.[0]?.properties?.taxonomy
          ? `${item.properties.tagsTaxonomy[0].properties.taxonomy}.id`
          : '',
        isOpen: item.properties.isOpen,
        filterItems: item.properties.filterItems.map((filterItem) => filterItem),
        tagsTaxonomy: item.properties.tagsTaxonomy,
        isCheckedAndHidden: item.properties.isCheckedAndHidden,
      }))
    : null;

export const parseProductQuizData = (
  param: UmbracoProductQuiz.IData
): IParsedAlgoliaQuizQuestion[] | null =>
  param?.questions?.length
    ? param.questions.map(({ properties: { answers, title, tagsTaxonomy } }) => ({
        attributeForFaceting: tagsTaxonomy?.[0]?.properties?.taxonomy
          ? `${tagsTaxonomy[0].properties.taxonomy}.id`
          : '',
        title,
        answers: answers.map(({ properties: { tag } }) => tag[0]),
        tagsTaxonomy,
      }))
    : null;
