import React, { FC } from 'react';
import { graphql } from 'gatsby';

import IconCustom from 'common/IconCustom';
import Button from 'common/Button';

import { IButtonWithIconProps } from './model';

import './ButtonWithIcon.scss';

const ButtonWithIcon: FC<IButtonWithIconProps> = ({
  link,
  target,
  customClass,
  ariaLabel,
  name,
  icon,
}) => (
  <Button link={link} target={target} className={customClass} ariaLabel={ariaLabel}>
    <span className="external-link__text">{name}</span>
    <IconCustom icon={icon} />
  </Button>
);

export const query = graphql`
  fragment FragmentLinkWithIcon on TLinkWithIconStructure {
    properties {
      link {
        name
        url
        target
      }
      linkAriaLabel
      icon
    }
  }
`;

export default ButtonWithIcon;
