import sha256 from 'sha256';

import { NEWSLETTER_NAME } from 'components/Newsletter/newsLetterConstants';

declare global {
  interface Window {
    dataLayer: any[];
    Krux: (
      namespace: string,
      consent: string,
      errorHandler: (err: any) => void,
      data: { [key: string]: boolean }
    ) => void;
  }
}

export const validateFieldExistence = (value: string) => value.trim() !== '';

export const validateEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email.toLowerCase());
};

export const validateName = (name: string) => /([a-zA-Z `'"’,.:;!?-])/.test(name);

export const pushSHAtoDataLayer = (email: string) => {
  if (!email) {
    return;
  }
  const data = email.trim().toLowerCase();
  if (!data) {
    return;
  }

  window.dataLayer && window.dataLayer.push({ email_sha256: sha256(data) });
  window.Krux &&
    window.Krux(
      'ns:reckittbenckiserukhealth',
      'page:load',
      (err) => {
        console.error(`Krux at NewsLetter has an error: ${err}`);
      },
      { pageView: true }
    );
};

export const setCookiesExpires = (time: number) => {
  const date: Date = new Date(Date.now() + time);

  document.cookie = `${NEWSLETTER_NAME}=StrepsilsNewsLetter; path=/; expires=${date}`;
};
