import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IPropsBottomFooter } from './model';

import './BottomFooter.scss';
import './ShadowBottomFooter.scss';

const BottomFooter: FC<IPropsBottomFooter> = ({ copyRight }) => (
  <div className="bottom-footer" data-test="bottomFooter">
    <DangerouslySetInnerHtml html={copyRight} />
  </div>
);

export default BottomFooter;
