import React, { FC, ReactElement } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import { Container } from 'layout';

import './AnefpCard.scss';
import { IPropsAnefpCard } from './models';

const AnefpCard: FC<IPropsAnefpCard> = ({ anefpData }): ReactElement | null => (
  <div data-test="AnefpCard" className="anefp-card">
    <Container>
      <GatsbyImage
        image={{ ...anefpData.image, mobileImage: anefpData.mobileImage.gatsbyImage }}
        alt={anefpData.imageAlt}
        objectFit="contain"
        className="anefp-card__image-container"
      />
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentAnefpCard on TAnefpBlock {
    image {
      ...FragmentGatsbyProps
      gatsbyImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    mobileImage: image {
      ...FragmentGatsbyProps
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    imageAlt
  }
`;

export default AnefpCard;
