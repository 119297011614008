import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IPropsAdditionalInfo } from './model';
import './AdditionalInfo.scss';
import './ShadowAdditionalInfo.scss';

const AdditionalInfo: FC<IPropsAdditionalInfo> = ({ additionalInfo }) => (
  <div data-test="AdditionalInfo" className="additional-info">
    <DangerouslySetInnerHtml className="additional-info-text" html={additionalInfo} />
  </div>
);

export default AdditionalInfo;
