import React, { FC, useState, useCallback, useEffect, lazy, Suspense } from 'react';
import { Experiment, Variant } from 'react-optimize';

import { WEEK, NEWSLETTER_NAME } from './newsLetterConstants';
import { setCookiesExpires } from './NewsletterSignUp/helpers';
import { IPropsNewsLetter } from './models';

const NewsletterSignUp = lazy(() => import('./NewsletterSignUp'));
const NewsletterThankYou = lazy(() => import('./NewsletterPopup'));

const renderLoader = () => null;

const Newsletter: FC<IPropsNewsLetter> = ({ newsletter, arrowNext, arrowPrev }) => {
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handlerCloseModal = useCallback(() => {
    setCookiesExpires(WEEK);
    setShowModal(false);
  }, []);

  const handlerOpenThankYouLetter = useCallback(() => {
    setShowModal(false);
    setCloseModal(true);
  }, []);

  const handlerCloseThankYouLetter = useCallback(() => setCloseModal(false), []);

  useEffect(() => {
    const timer = setTimeout(() => setShowModal(true), 30000);
    if (document.cookie.match(NEWSLETTER_NAME)) {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showModal ? (
        <Suspense fallback={renderLoader()}>
          <NewsletterSignUp
            openThankYouLetter={handlerOpenThankYouLetter}
            handlerCloseModal={handlerCloseModal}
            signUpForm={newsletter.signUpForm}
          />
        </Suspense>
      ) : null}
      <Experiment id={process.env.GATSBY_GOOGLE_OPTIMIZE_EXPERIMENT_ID || ''}>
        {closeModal ? (
          <>
            {newsletter?.thankYouLetter
              ? newsletter.thankYouLetter.map((thankYouLetter, index) => (
                  <Variant key={`newsletter-variant-${index.toString()}`} id={index.toString()}>
                    <Suspense fallback={renderLoader()}>
                      <NewsletterThankYou
                        handlerCloseThankYouLetter={handlerCloseThankYouLetter}
                        thankYouLetter={thankYouLetter}
                        arrowNext={arrowNext}
                        arrowPrev={arrowPrev}
                      />
                    </Suspense>
                  </Variant>
                ))
              : null}
          </>
        ) : null}
      </Experiment>
    </>
  );
};

export default Newsletter;
