import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IPropsReferences } from './model';
import './References.scss';

const References: FC<IPropsReferences> = ({ referencesBlock }) => (
  <>
    {referencesBlock?.length ? (
      <div className="footer-references" data-test="footer-references">
        <h3 className="footer-references__title">{referencesBlock?.[0].properties.title}</h3>
        <ul className="footer-references__list">
          {referencesBlock?.[0].properties.content.map(({ properties: { item } }) => (
            <li key={item}>
              <DangerouslySetInnerHtml html={item} />
            </li>
          ))}
        </ul>
      </div>
    ) : null}
  </>
);

export default References;
