import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { textWithOutSpaces } from 'utils/stringUtils/stringUtils';

import Connect from 'layout/Footer/Connect';

import { IPropsContactInformation } from './model';
import './ContactInformation.scss';
import './ShadowContactInformation.scss';

const ContactInformation: FC<IPropsContactInformation> = ({
  helpAndSupport,
  helpAndSupportConnected,
}) => {
  if (!helpAndSupport?.[0]?.properties && !helpAndSupportConnected?.[0]?.properties) {
    return null;
  }
  const helpAndSupportProperties = helpAndSupport?.[0]?.properties;

  return (
    <div className="contact-information" data-test="ContactInformation">
      {helpAndSupportProperties?.title ? (
        <h3 className="contact-title">{helpAndSupportProperties.title}</h3>
      ) : null}

      {helpAndSupportProperties?.content?.length
        ? helpAndSupportProperties.content.map(
            ({ properties: { phoneNumber, textBeforeNumber } }) => (
              <div className="footer-contact" key={`${phoneNumber}${textBeforeNumber}`}>
                <span className="footer-contact__text">{textBeforeNumber}</span>
                <a
                  href={`tel:${textWithOutSpaces(phoneNumber, '')}`}
                  className="footer-contact__link"
                >
                  {phoneNumber}
                </a>
              </div>
            )
          )
        : null}

      {helpAndSupportProperties?.address ? (
        <DangerouslySetInnerHtml html={helpAndSupportProperties.address} className="address" />
      ) : null}

      {helpAndSupportConnected?.[0]?.properties ? (
        <Connect connect={helpAndSupportConnected} />
      ) : null}
    </div>
  );
};
export default ContactInformation;
