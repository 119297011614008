import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import { ToPascalCaseFunctionType } from './models';

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const getId = (url: string): string => {
  const newUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

  return newUrl[2] ? newUrl[2].split(/[^0-9a-z_-]/i)[0] : newUrl[0];
};

export const sliceText = (str: string, limit = 80): string => {
  const temp = str.split(/\s/);
  let tempStr = temp[0];
  for (let i = 1; i < temp.length; i++) {
    if (tempStr.length + temp[i].length + 1 >= limit) {
      tempStr += '…';
      break;
    } else {
      tempStr += ` ${temp[i]}`;
    }
  }

  return tempStr;
};

export const isSameString = (strA: string, strB: string): boolean =>
  strA.toLowerCase() === strB.toLowerCase();

export const textWithOutSpaces = (
  text: string | null | undefined,
  divider?: string
): string | undefined =>
  text ? text.split(/\s/).join(typeof divider === 'undefined' ? '_' : divider) : undefined;

const stringUtils = {
  toPascalCase,
};

export default stringUtils;
