import React, { FC, useRef } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import useClickOutside from 'hooks/useClickOutside';
import IconCustom from 'common/IconCustom';
import { IPropsLanguageSelector } from './model';

import './LanguageSelector.scss';
import './ShadowLanguageSelector.scss';

const LanguageSelector: FC<IPropsLanguageSelector> = ({
  handlerVisibleLanguageSelector,
  handlerCloseLanguageSelector,
  openLanguageSelector,
  languageSelectorAriaLabel,
  languagesHeader,
  currentLanguageLetters,
}) => {
  const languageSelectorRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(languageSelectorRef, handlerCloseLanguageSelector(false));

  return (
    <>
      {languagesHeader.length ? (
        <div
          data-test="languageSelector"
          className="language-selector"
          ref={languageSelectorRef}
          onMouseEnter={handlerCloseLanguageSelector(true)}
          onMouseLeave={handlerCloseLanguageSelector(false)}
        >
          <button
            type="button"
            className="language-selector__button"
            aria-label={languageSelectorAriaLabel}
            onClick={handlerVisibleLanguageSelector}
          >
            <span>{currentLanguageLetters}</span>
            <IconCustom
              icon="arrow"
              className={classNames('language-selector__icon', {
                'language-selector__icon--open': openLanguageSelector,
              })}
            />
          </button>
          <div
            className={classNames('language__categories', {
              'language__categories--open': openLanguageSelector,
            })}
          >
            {languagesHeader?.map(({ languageLabel, link }) => (
              <Link key={languageLabel} className="language__category" to={link || '/'}>
                {languageLabel}
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div className="language-selector__empty-block" />
      )}
    </>
  );
};

export default LanguageSelector;
