import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';

import { ILogoProps } from './models';
import './Logo.scss';
import './ShadowLogo.scss';

const Logo: FC<ILogoProps> = ({
  brandSettings: {
    brandLogo,
    brandLogoAlt,
    brandLogoLink,
    brandLogoLinkAriaLabel,
    brandLogoText,
    brandLogoTextMedia,
  },
  className,
  pageLogo,
  pageLogoAlt,
}) => (
  <Link
    className={classNames('brand-logo__link', className)}
    to={brandLogoLink[0].url}
    aria-label={brandLogoLinkAriaLabel}
  >
    <GatsbyImage className="brand-logo__text" image={brandLogoText} />
    <GatsbyImage className="brand-logo__text" image={brandLogoTextMedia} />
    <GatsbyImage
      className={classNames('brand-logo__glyph', {
        'single-glyph':
          (!brandLogoText?.gatsbyImage && !brandLogoTextMedia?.gatsbyImage) || pageLogo,
      })}
      image={pageLogo || brandLogo}
      alt={pageLogoAlt || brandLogoAlt}
      objectFit={pageLogo ? 'contain' : 'cover'}
    />
  </Link>
);

export default Logo;
