import React, {
  FC,
  lazy,
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { useLocation } from '@reach/router';

import { parseButtonComposition } from 'utils/parsers';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { scrollToTarget } from 'utils/browser';
import { ELEMENTS } from 'utils/constants';

import IconCustom from 'common/IconCustom';

import './OtcDisclaimer.scss';
import './ShadowOtcDisclaimer.scss';
import { IPropsOtcDisclaimer } from './models';

const OtcDisclaimerContentLoadable = lazy(
  () => import('components/OtcDisclaimer/OtcDisclaimerContent')
);
const renderLoader = () => null;
const SessionStorageKey = 'otcDisclaimerStatus';

const OtcDisclaimer: FC<IPropsOtcDisclaimer> = ({ otcDisclaimer }): ReactElement | null => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isInitiated, setInitiated] = useState<boolean>(false);
  const { isMobile } = useScreenRecognition();

  const { pathname, search } = useLocation();

  const handleInitiatedStatus = useCallback(
    () => () => {
      setInitiated(true);
    },
    []
  );

  const handleFocusWithin = useCallback(
    (e: React.FocusEvent<HTMLDivElement | any>) => {
      const isFocusWithin = e.currentTarget.contains(e.relatedTarget);

      if (!isFocusWithin && isSidebarOpen) {
        setSidebarOpen(false);
        sessionStorage.setItem(SessionStorageKey, String(false));
      }
    },
    [isSidebarOpen]
  );

  const isInitiallyOpen = useMemo(() => {
    const { openOnPages, openOnQueryParam } = otcDisclaimer || {};
    const searchParams = new URLSearchParams(search);
    const isOnPage = openOnPages?.some(({ url }) => url === pathname);
    const isQueryParamContain = openOnQueryParam?.some(({ value }) => searchParams.has(value));

    return isOnPage || isQueryParamContain;
  }, [search]);

  useEffect(() => {
    if (isInitiallyOpen) {
      scrollToTarget(ELEMENTS.HEADER_ID, 0, true);
      sessionStorage.setItem(SessionStorageKey, String(true));
      setInitiated(true);
      setSidebarOpen(true);
    }

    return () => {
      sessionStorage.removeItem(SessionStorageKey);
    };
  }, []);

  useEffect(() => {
    if (isInitiated) {
      return;
    }
    const handleOnLoad = handleInitiatedStatus();

    // For handling the switching toggler
    if (window.document.readyState === 'complete') {
      handleOnLoad();

      return;
    }

    window.addEventListener('load', handleOnLoad);

    return () => {
      window.removeEventListener('load', handleOnLoad);
    };
  }, [isInitiated]);

  useEffect(() => {
    const savedValue = sessionStorage.getItem(SessionStorageKey);
    if (isMobile && !savedValue) {
      setSidebarOpen(false);
    }

    if (!savedValue) {
      return;
    }

    setSidebarOpen(savedValue === 'true');
  }, [isMobile]);

  const handleSidebarVisibility = useCallback(() => {
    setSidebarOpen((oldValue: boolean) => {
      const newValue = !oldValue;
      if (newValue) {
        scrollToTarget(ELEMENTS.HEADER_ID, 0, true);
      }
      sessionStorage.setItem(SessionStorageKey, String(newValue));

      return newValue;
    });
  }, []);

  if (!otcDisclaimer) {
    return null;
  }

  const closeCTA = parseButtonComposition(otcDisclaimer.closeCTAButton);
  const openCTA = parseButtonComposition(otcDisclaimer.openCTAButton);

  if (!closeCTA || !openCTA || !otcDisclaimer.text) {
    return null;
  }

  return (
    <div
      data-test="OtcDisclaimer"
      className={classNames('otc-disclaimer', {
        open: isSidebarOpen,
      })}
      onBlur={handleFocusWithin}
    >
      <div
        className={classNames('cover', {
          open: isSidebarOpen,
        })}
        onClick={handleSidebarVisibility}
        role="presentation"
      />

      <div className="otc-disclaimer__btn-wrapper">
        <button
          type="button"
          className="otc-disclaimer__btn"
          onClick={handleSidebarVisibility}
          aria-label={isSidebarOpen ? closeCTA.ariaLabel : openCTA.ariaLabel}
        >
          <span className="otc-disclaimer__btn--info-block">
            <span className="otc-disclaimer__btn--title">
              {isSidebarOpen ? closeCTA.label : openCTA.label}
            </span>
            <IconCustom icon="cross" />
          </span>
        </button>
      </div>

      {isInitiated ? (
        <Suspense fallback={renderLoader()}>
          <OtcDisclaimerContentLoadable
            title={otcDisclaimer.title}
            text={otcDisclaimer.text}
            isFocused={isInitiallyOpen}
          />
        </Suspense>
      ) : null}
    </div>
  );
};

export default OtcDisclaimer;
