import React, { FC } from 'react';

import DesktopNavItem from '../DesktopNavItem';

import './DesktopNavigation.scss';
import { IPropsDesktopNavigation } from './model';

const DesktopNavigation: FC<IPropsDesktopNavigation> = ({ navigation }) => (
  <ul data-test="desktopNavigation" className="desktop-nav">
    {navigation?.length
      ? navigation.map((element) => <DesktopNavItem key={element.properties.label} {...element} />)
      : null}
  </ul>
);

export default DesktopNavigation;
