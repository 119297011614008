import React, { FC } from 'react';

import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import { IPropsConnect } from './model';

import './Connect.scss';
import './ShadowConnect.scss';

const Connect: FC<IPropsConnect> = ({
  connect: [
    {
      properties: { connectTitle, content },
    },
  ],
}) => (
  <div className="connect" data-test="Connect">
    <h2 className="connect-title">{connectTitle}</h2>
    <div className="social-list">
      {content?.length
        ? content.map(({ properties: { mediaLink, mediaTitle, mediaLinkIcon, blank } }) => (
            <div className="social-item" key={mediaTitle}>
              <Button
                link={mediaLink}
                target={blank === '1' ? '_blank' : undefined}
                className="social-item__link"
                ariaLabel={mediaTitle}
              >
                <GatsbyImage alt={mediaTitle} image={mediaLinkIcon} />
              </Button>
            </div>
          ))
        : null}
    </div>
  </div>
);
export default Connect;
