import React, { FC } from 'react';

import IconCustom from 'common/IconCustom';

import { IPropsBackToTopButton } from './model';
import './BackToTopButton.scss';
import './ShadowBackToTopButton.scss';

const BackToTopButton: FC<IPropsBackToTopButton> = ({ ariaLabelBackToTopButton }) => {
  const clickHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      data-test="BackToTopButton"
      onClick={clickHandler}
      className="button-back-to-top"
      type="button"
      aria-label={ariaLabelBackToTopButton}
    >
      <IconCustom icon="arrow3-up" className="back-to-top" />
    </button>
  );
};

export default BackToTopButton;
