import React, { FC } from 'react';

import { ELEMENTS } from 'utils/constants';

import Container from 'layout/Container';
import TopFooter from 'layout/Footer/TopFooter';
import BottomFooter from 'layout/Footer/BottomFooter';
import BackToTopButton from 'layout/Footer/BackToTopButton';
import References from 'layout/Footer/References';
import FooterBottomBanner from 'layout/Footer/FooterBottomBanner';
import IconFeatureList from 'components/IconFeatureList';

import { IPropsHerbalFooter } from './models';
import './HerbalFooter.scss';
import './ShadowHerbalFooter.scss';

const HerbalFooter: FC<IPropsHerbalFooter> = ({
  footer,
  footer: { copyRight, ariaLabelBackToTopButton, referencesBlock, footerBottomBanner, iconsList },
}) => (
  <footer className="footer" data-test="herbal-footer" id={ELEMENTS.FOOTER_ID}>
    <div className="footer-main-content">
      <Container className="footer-container">
        <BackToTopButton ariaLabelBackToTopButton={ariaLabelBackToTopButton} />
        <TopFooter footer={footer} />
        <References referencesBlock={referencesBlock} />
        <BottomFooter copyRight={copyRight} />
      </Container>
    </div>
    {footerBottomBanner?.length ? (
      <div className="footer-secondary-content">
        <Container className="footer-secondary-container">
          <FooterBottomBanner footerBottomBanner={footerBottomBanner} />
        </Container>
      </div>
    ) : null}
    {iconsList ? <IconFeatureList iconsList={iconsList} /> : null}
  </footer>
);

export default HerbalFooter;
