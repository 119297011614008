import React, { FC } from 'react';
import classnames from 'classnames';

import { Element } from 'layout';

import { ContainerProps } from './models';

import './Container.scss';

const Container: FC<ContainerProps> = ({
  element,
  className,
  fluid,
  fullWidth,
  innerRef,
  children,
  ...rest
}) => (
  <Element
    element={element || 'div'}
    className={classnames('container', className, {
      [`container--fluid`]: fluid,
      [`container--full-width`]: fullWidth,
    })}
    innerRef={innerRef}
    {...rest}
  >
    {children}
  </Element>
);

export default Container;
