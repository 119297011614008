import { UmbracoLanguages } from '@shared/types';
import { ILanguagesFinal } from './model';

const parseLanguagesToArray = (
  languagesArray: [UmbracoLanguages.IStructure],
  urlsArray: [string[]],
  currentLanguage: string
) => {
  const languagesFinal: ILanguagesFinal = {};

  languagesArray.forEach(
    ({ properties: { languageLabel, languageLetters, languageLettersShort } }) => {
      if (languageLettersShort !== currentLanguage) {
        languagesFinal[languageLetters] = {
          languageLabel,
          languageLettersShort,
        };
      }
    }
  );

  urlsArray.forEach((pageUrl: string[]) => {
    if (languagesFinal[pageUrl[0]]) {
      languagesFinal[pageUrl[0]] = { ...languagesFinal[pageUrl[0]], link: pageUrl[1] };
    }
  });

  return languagesFinal;
};

export default {
  parseLanguagesToArray,
};
