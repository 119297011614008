import React, { FC, useState, useCallback, useRef, ChangeEvent, FormEvent } from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import FocusTrap from 'focus-trap-react';

import IconCustom from 'common/IconCustom';
import { ALGOLIA_URL_PARAMS } from 'utils/algoliaFilters/constants';
import useClickOutside from 'hooks/useClickOutside';

import { IPropsSearchBar } from './model';
import './SearchBar.scss';
import './ShadowSearchBar.scss';

const SearchBar: FC<IPropsSearchBar> = ({
  closeSearchBar,
  handler,
  closeSearchFormAriaLabel,
  searchIconFormAriaLabel,
  searchPlaceholder,
  searchBarText,
  searchBarTextClose,
  searchRedirect,
  handlerCloseSearchBar,
  searchTitle,
  clickOutsideExceptionsIds,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const inputSearch = useRef<HTMLInputElement>(null);
  const searchBlock = useRef<HTMLDivElement>(null);
  const inputValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
    []
  );
  const removeInputValue = useCallback(() => {
    setInputValue('');
    if (inputSearch && inputSearch.current) {
      inputSearch.current.focus();
    }
  }, []);

  const redirectToSearchResultPage = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      handler();
      setInputValue('');
      navigate(
        encodeURI(`${searchRedirect[0].url}?${ALGOLIA_URL_PARAMS.querySearch}=${inputValue}`)
      );
    },
    [searchRedirect, inputValue]
  );

  useClickOutside(searchBlock, handlerCloseSearchBar(false), clickOutsideExceptionsIds);

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div
        ref={searchBlock}
        data-test="searchBar"
        className={classNames('search-bar', { 'search-bar--open': closeSearchBar })}
      >
        <div className="search-bar__top-part">
          <div className="search-bar__text">{searchBarText}</div>
          <form className="search-bar__form" onSubmit={redirectToSearchResultPage}>
            <input
              ref={inputSearch}
              value={inputValue}
              onChange={inputValueChange}
              type="text"
              className="form__input"
              placeholder={searchPlaceholder || ''}
              title={searchTitle || 'search'}
              aria-label={searchTitle}
            />
            <button
              aria-label={closeSearchFormAriaLabel}
              onClick={removeInputValue}
              type="button"
              className="form__close-button"
            >
              <IconCustom icon="cross" className="form__close-button-icon" />
            </button>
            <button
              aria-label={searchIconFormAriaLabel}
              type="submit"
              className="form__search-button"
            >
              <IconCustom icon="search" className="form__search-button-icon" />
            </button>
          </form>
        </div>
        <button
          aria-label={closeSearchFormAriaLabel}
          type="button"
          className="search-bar__bottom-part"
          onClick={handler}
        >
          <IconCustom icon="cross" className="bottom-part__icon" />
          <span className="bottom-part__text">{searchBarTextClose}</span>
        </button>
      </div>
    </FocusTrap>
  );
};

export default SearchBar;
