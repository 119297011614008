import React, { FC } from 'react';
import { Link } from 'gatsby';

import { INavFooterProps } from './model';
import './NavigationList.scss';
import './ShadowNavigationList.scss';

const NavigationList: FC<INavFooterProps> = ({ footerNavigation, footerNavigationTitle }) => (
  <div data-test="NavigationList" className="navigation-list">
    {footerNavigationTitle ? (
      <h3 className="navigation-list-title">{footerNavigationTitle}</h3>
    ) : null}
    <ul className="navigation-list-items">
      {footerNavigation?.length
        ? footerNavigation.map(({ properties: { label, url } }) => (
            <li className="list-item" key={label}>
              <Link to={url?.[0].url}>{label}</Link>
            </li>
          ))
        : null}
    </ul>
  </div>
);

export default NavigationList;
