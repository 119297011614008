import React, { FC, ReactElement } from 'react';
import { graphql } from 'gatsby';
import { UmbracoIconFeature } from '@shared/types';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';

import './IconFeatureList.scss';
import { IPropsIconFeatureList } from './models';

const IconFeatureList: FC<IPropsIconFeatureList> = ({ iconsList }): ReactElement | null =>
  iconsList?.[0]?.properties?.items.length ? (
    <div data-test="IconFeatureList" className="promo-icons-list">
      {iconsList[0].properties.items.map(
        ({ properties: { label, icon, iconAlt, iconId } }: UmbracoIconFeature.IStructure) => (
          <div className="promo-icon-item" key={iconId?.[0]?.properties?.names?.[0] || label}>
            <div className="promo-icon-wrapper">
              <GatsbyImage image={icon} alt={iconAlt} className="promo-icon" />
            </div>
            <div className="promo-info">
              <DangerouslySetInnerHtml html={label} className="promo-text" />
            </div>
          </div>
        )
      )}
    </div>
  ) : null;

export const query = graphql`
  fragment FragmentIconFeatureList on TIconFeature {
    properties {
      items {
        properties {
          icon {
            ...FragmentGatsbyImage
          }
          iconAlt
          label
        }
      }
    }
  }
`;

export default IconFeatureList;
