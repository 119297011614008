import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import MobileSubMenu from '../MobileSubMenu';
import { IPropsMobileNavigation } from './model';

import './MobileNavigation.scss';
import './ShadowMobileNavigation.scss';

const MobileNavigation: FC<IPropsMobileNavigation> = ({ closeBurger, navigation }) => {
  const handlerReloadPage = useCallback(
    (link: string) => () => {
      if (window.location.href.includes(link)) {
        setTimeout(() => window.location.reload(), 100);
      }
    },
    []
  );

  return (
    <div
      data-test="mobileNavigation"
      className={classNames('mobile-navigation__menu', {
        'mobile-navigation__menu--open': closeBurger,
      })}
    >
      {navigation.map(({ properties: { navigationWithSubMenu, label, url } }) => {
        if (navigationWithSubMenu) {
          return (
            <MobileSubMenu
              key={label}
              className={classNames('mobile-navigation__nav-item', {
                'mobile-navigation__nav-item--open': closeBurger,
              })}
              subMenu={navigationWithSubMenu}
              handlerReloadPage={handlerReloadPage}
            >
              {label}
            </MobileSubMenu>
          );
        }
        if (url) {
          return (
            <Link
              to={url[0].url}
              key={label}
              className={classNames('mobile-navigation__nav-item ', {
                'mobile-navigation__nav-item--open': closeBurger,
              })}
              activeClassName="mobile-navigation__nav-item--active"
              onClick={handlerReloadPage(url[0].url)}
            >
              {label}
            </Link>
          );
        }

        return null;
      })}
    </div>
  );
};

export default MobileNavigation;
