import React, { FC } from 'react';
import classnames from 'classnames';

import { ELEMENTS } from 'utils/constants';

import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Footer from 'layout/Footer';
import HerbalFooter from 'layout/HerbalFooter/HerbalFooter';
import Header from 'layout/Header';
import AnefpCard from 'layout/AnefpCard';
import NewsLetter from 'components/Newsletter';
import OtcDisclaimer from 'components/OtcDisclaimer';

import 'styles/main.scss';
import './Layout.scss';
import { IPropsLayout } from './model';

const Layout: FC<IPropsLayout> = ({
  children,
  defaultCompositions: {
    siteSettings: { searchPlaceholder, searchTitle },
    brandSettings,
    header,
    siteSettings,
    commonSettings,
    footer,
    newsletter,
    otcDisclaimerSettings,
    anefpSettings,
    urls,
  },
  customCompositions,
  anefpBlockCustomization,
  className,
  seo,
  pageLogo,
  pageLogoAlt,
}) => (
  <div className={classnames('layout', className)}>
    <Seo siteSettings={siteSettings} brandSettings={brandSettings} seo={seo} />
    <PageSchema
      {...{
        type: 'WebPage',
        siteSettings,
        brandSettings,
        seo,
        name: 'test',
      }}
    />
    <a
      className="sr-only"
      href={`#${ELEMENTS.CONTENT_ID}`}
      // eslint-disable-next-line  jsx-a11y/no-interactive-element-to-noninteractive-role
      role="navigation"
      aria-label={commonSettings.skipToMainContentText}
    >
      {commonSettings.skipToMainContentText}
    </a>
    <Header
      brandSettings={brandSettings}
      header={header}
      searchPlaceholder={searchPlaceholder}
      searchTitle={searchTitle}
      urls={urls}
      languages={siteSettings.languages}
      currentLanguageLetters={siteSettings.currentLanguageLetters}
      pageLogo={pageLogo}
      pageLogoAlt={pageLogoAlt}
    />
    {process.env.GATSBY_GOOGLE_OPTIMIZE_EXPERIMENT_ID &&
    process.env.GATSBY_GOOGLE_OPTIMIZE_EXPERIMENT_ID !== '0' &&
    newsletter ? (
      <NewsLetter
        newsletter={newsletter}
        arrowPrev={siteSettings.ariaLabelPrev}
        arrowNext={siteSettings.ariaLabelNext}
      />
    ) : null}
    <main id={ELEMENTS.CONTENT_ID} className="main">
      {children}
      {otcDisclaimerSettings ? <OtcDisclaimer otcDisclaimer={otcDisclaimerSettings} /> : null}
      {!anefpBlockCustomization?.isHideBlock && anefpSettings ? (
        <AnefpCard anefpData={anefpSettings} />
      ) : null}
    </main>
    {customCompositions?.herbalFooter ? (
      <HerbalFooter footer={customCompositions.herbalFooter} />
    ) : (
      <Footer footer={footer} />
    )}
  </div>
);
export default Layout;
